import React, {Component} from 'react';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';

import RenderField from '../../HelperComponents/RenderField/RenderField';
import AuthButton from '../../HelperComponents/Buttons/AuthButton/AuthButton';
import SnackBar from '../../HelperComponents/SnackBar/SnackBar';

import { postLogin } from "../../../actions/authActions";

class Login extends Component {
    state = {
        loading: false,
        errorOpen: false
    };

    UNSAFE_componentWillMount() {
        localStorage.clear();
        sessionStorage.clear();
    }

    submitForm = data => {
        const { postLogin, history } = this.props;
        this.setState({loading: true, errorOpen: false});
        return postLogin(data).then(res => {
            if(res.payload && res.payload.status && res.payload.status === 200) {
                localStorage.token = res.payload.data.token;
                history.push('/admin-dashboard/');
            } else {
                this.setState({loading: false, errorOpen: res.error.response.data.message || res.error.response.data.detail});
                throw new SubmissionError({...res.error.response.data, _error: res.error.response.data.message ? ["Your account has been temporarily deactivated. Please contact support@gaming-stars.net"] : res.error.response.data.detail});
            }
        });
    };

    closeError = () => {
        this.setState({errorOpen: false});
    };

    render(){
        const { handleSubmit, submitting, pristine, valid, error } = this.props;
        const { loading, errorOpen } = this.state;
        return (
            <section className="auth_section">
                <div className="auth_section_block">
                    <h2 className="auth_title">Login</h2>
                    <p className="auth_subtitle">Please log in to your account</p>
                    <form onSubmit={handleSubmit(this.submitForm)}>
                        <Field name="email" type="text" component={RenderField} label="Email" />
                        <Field name="password" type="password" component={RenderField} label="Password" />
                        <div className="auth_buttons_wrapper">
                            <AuthButton
                                formAction
                                disabled={submitting || pristine || !valid}
                                loading={loading}
                            >
                                Login
                            </AuthButton>
                        </div>
                    </form>
                </div>
                <SnackBar
                    open={errorOpen && error !== undefined}
                    onClose={this.closeError}
                    classes="error"
                >
                    {error}
                </SnackBar>
            </section>
        );
    }
}

const validate = values => {
    const errors = {};
    if (!values.email) {
        errors.email = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(values.email)) {
        errors.email = 'Invalid email'
    }
    if (!values.password) {
        errors.password = 'Required'
    } else if (values.password.length < 8) {
        errors.password = 'Must be 8 characters or more'
    }
    return errors
};

Login = reduxForm({
    form: 'LoginForm',
    validate
})(Login);

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        postLogin,
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(Login);