import React, {Component} from 'react';
import { Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import DialogComponent from "../HelperComponents/DialogComponent/DialogComponent";
import DefaultButton from "../HelperComponents/Buttons/DefaultButton/DefaultButton";

import ExitIcon from '../../assets/image/exit_app.svg';
import ExpandIcon from '@material-ui/icons/ExpandMore';
import LogoImg from '../../assets/image/header_logo_img.svg';

import './Header.scss';

class Header extends Component {
    state = {
        openDialog: false,
        anchorEl: null
    };

    toggleDialog = () => {
        this.setState(({openDialog}) => ({
            openDialog: !openDialog
        }));
    };

    handleClick = event => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null
        });
    };

    logOut = () => {
        const { history } = this.props;
        localStorage.clear();
        history.push('/auth');
    };

    render(){
        const { email } = this.props;
        const { openDialog, anchorEl } = this.state;
        const open = Boolean(anchorEl);
        return (
            <header className="blogger_header">
                <Link
                    to="/admin"
                    className="header-logo"
                >
                    <img src={LogoImg} alt="logo img"/>
                </Link>
                <div className="header_options">
                    <Button
                        classes={{
                            root: `app-menu_button ${open ? 'menu_open' : null}`
                        }}
                        onClick={this.handleClick}
                    >
                        <p>{email}</p>
                        <ExpandIcon/>
                    </Button>
                </div>
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    classes={{
                        paper: 'app_head_popover'
                    }}
                >
                    <div className="app-menu">
                        <button
                            onClick={this.toggleDialog}
                        >
                            Log out
                        </button>
                    </div>
                </Popover>
                <DialogComponent
                    open={openDialog}
                    onClose={this.toggleDialog}
                >
                    <div className="logout_wrapper">
                        <h3>Warning!</h3>
                        <img src={ExitIcon} alt="exit icon"/>
                        <p>Are you sure you want to quit?</p>
                        <div className="logout_buttons">
                            <DefaultButton
                                type="red"
                                size="small"
                                onClick={this.toggleDialog}
                            >
                                No
                            </DefaultButton>
                            <DefaultButton
                                type="green"
                                size="small"
                                onClick={this.logOut}
                            >
                                Yes
                            </DefaultButton>
                        </div>
                    </div>
                </DialogComponent>
            </header>
        );
    }
}

export default Header;