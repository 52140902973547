import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router';
import AuthReducer from './reduceAuth';
import DashboardReducer from './reduceDashboard';
import {reducer as formReducer} from 'redux-form';

const rootReducer = (history) => combineReducers({
    router: connectRouter(history),
    form: formReducer,
    auth: AuthReducer,
    dashboard: DashboardReducer,
});

export default rootReducer;