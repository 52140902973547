//////////////////////////////auth actions//////////////////////////////

export const LOGIN          =   'LOGIN';
export const LOGIN_FAIL     =   'LOGIN_FAIL';

//////////////////////////////Dashboard/////////////////////////////////

export const MY_PROFILE            =   'MY_PROFILE';
export const MY_PROFILE_SUCCESS    =   'MY_PROFILE_SUCCESS';
export const MY_PROFILE_FAIL    =   'MY_PROFILE_FAIL';

export const GET_TOTAL_REGISTERED_USERS            =   'GET_TOTAL_REGISTERED_USERS';
export const GET_TOTAL_REGISTERED_USERS_SUCCESS    =   'GET_TOTAL_REGISTERED_USERS_SUCCESS';

export const GET_ACTIVE_USERS            =   'GET_ACTIVE_USERS';
export const GET_ACTIVE_USERS_SUCCESS    =   'GET_ACTIVE_USERS_SUCCESS';

export const GET_ONE_EURO_GAMES            =   'GET_ONE_EURO_GAMES';
export const GET_ONE_EURO_GAMES_SUCCESS    =   'GET_ONE_EURO_GAMES_SUCCESS';

export const GET_FIVE_EURO_GAMES            =   'GET_FIVE_EURO_GAMES';
export const GET_FIVE_EURO_GAMES_SUCCESS    =   'GET_FIVE_EURO_GAMES_SUCCESS';

export const GET_BOY_GAMES            =   'GET_BOY_GAMES';
export const GET_BOY_GAMES_SUCCESS    =   'GET_BOY_GAMES_SUCCESS';

export const GET_BOY_PAYOUTS            =   'GET_BOY_PAYOUTS';
export const GET_BOY_PAYOUTS_SUCCESS    =   'GET_BOY_PAYOUTS_SUCCESS';

export const GET_TOTAL_DEPOSITS            =   'GET_TOTAL_DEPOSITS';
export const GET_TOTAL_DEPOSITS_SUCCESS    =   'GET_TOTAL_DEPOSITS_SUCCESS';

export const GET_TOTAL_WITHDRAWALS            =   'GET_TOTAL_WITHDRAWALS';
export const GET_TOTAL_WITHDRAWALS_SUCCESS    =   'GET_TOTAL_WITHDRAWALS_SUCCESS';

export const GET_TOTAL_BONUSES            =   'GET_TOTAL_BONUSES';
export const GET_TOTAL_BONUSES_SUCCESS    =   'GET_TOTAL_BONUSES_SUCCESS';