import React, {Component} from 'react';
import {connect} from 'react-redux';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import clsx from "clsx";

import Loader from '../HelperComponents/Loader/Loader';
import LineChart from '../HelperComponents/LineChart/LineChart';

import {
    getTotalRegisteredUsers,
    getActiveUsers,
    getBOYGames,
    getBOYPayouts,
    getFiveEuroGames,
    getOneEuroGames,
    getTotalBonuses,
    getTotalDeposits,
    getTotalWithdrawals
} from "../../actions/dashboardActions";

import './Dashboard.scss';

class Dashboard extends Component {
    state = {
        loading: true,

        current_tab: "total_registered_users",
        tabs: [
            { label: "Total Users", value: "total_registered_users"},
            { label: "Active Users", value: "active_users"},
            { label: "1€ games", value: "one_euro_games"},
            { label: "5-25€ games", value: "five_euro_games"},
            { label: "BOY Games", value: "boy_games"},
            { label: "BOY Payouts", value: "boy_payouts"},
            { label: "Total deposits", value: "total_deposits"},
            { label: "Total Withdrawals", value: "total_withdrawals"},
            { label: "Total Bonuses", value: "total_bonuses"}
        ],

        current_period: 7,
        periods:  [
            { label: "1 week", value: 7 },
            { label: "1 month", value: 30 },
            { label: "1 year", value: 365 },
            { label: "All time", value: -1 },
        ]
    };

    componentDidMount() {
        this.doRequest();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.current_tab !== this.state.current_tab) {
            this.doRequest();
        }
    }

    doRequest = () => {
        const {
            getTotalRegisteredUsers, total_registered_users,
            getActiveUsers, active_users,
            getOneEuroGames, one_euro_games,
            getFiveEuroGames, five_euro_games,
            getBOYGames, boy_games,
            getBOYPayouts, boy_payouts,
            getTotalDeposits, total_deposits,
            getTotalWithdrawals, total_withdrawals,
            getTotalBonuses, total_bonuses,
        } = this.props;
        const { current_tab } = this.state;
        if (current_tab === 'total_registered_users' && !total_registered_users.categories) {
            getTotalRegisteredUsers().then(() => {
                this.setState({ loading: false });
            });
        } else if (current_tab === 'active_users' && !active_users.categories) {
            getActiveUsers().then(() => {
                this.setState({ loading: false });
            });
        } else if (current_tab === 'one_euro_games' && !one_euro_games.categories) {
            getOneEuroGames().then(() => {
                this.setState({ loading: false });
            });
        } else if (current_tab === 'five_euro_games' && !five_euro_games.categories) {
            getFiveEuroGames().then(() => {
                this.setState({ loading: false });
            });
        } else if (current_tab === 'boy_games' && !boy_games.categories) {
            getBOYGames().then(() => {
                this.setState({ loading: false });
            });
        } else if (current_tab === 'boy_payouts' && !boy_payouts.categories) {
            getBOYPayouts().then(() => {
                this.setState({ loading: false });
            });
        } else if (current_tab === 'total_deposits' && !total_deposits.categories) {
            getTotalDeposits().then(() => {
                this.setState({ loading: false });
            });
        } else if (current_tab === 'total_withdrawals' && !total_withdrawals.categories) {
            getTotalWithdrawals().then(() => {
                this.setState({ loading: false });
            });
        } else if (current_tab === 'total_bonuses' && !total_bonuses.categories) {
            getTotalBonuses().then(() => {
                this.setState({ loading: false });
            });
        }
    };
    chooseChartData = () => {
        const {
            total_registered_users,
            active_users,
            one_euro_games,
            five_euro_games,
            boy_games,
            boy_payouts,
            total_deposits,
            total_withdrawals,
            total_bonuses,
        } = this.props;
        const { current_tab } = this.state;
        if (current_tab === 'total_registered_users') {
            return total_registered_users;
        } else if (current_tab === 'active_users') {
            return active_users;
        } else if (current_tab === 'one_euro_games') {
            return one_euro_games;
        } else if (current_tab === 'five_euro_games') {
            return five_euro_games;
        } else if (current_tab === 'boy_games') {
            return boy_games;
        } else if (current_tab === 'boy_payouts') {
            return boy_payouts;
        } else if (current_tab === 'total_deposits') {
            return total_deposits;
        } else if (current_tab === 'total_withdrawals') {
            return total_withdrawals;
        } else if (current_tab === 'total_bonuses') {
            return total_bonuses;
        }
    };

    changeTab = (event, value) => {
        this.setState({ loading: true, current_tab: value });
    };

    changePeriod = value => {
        this.setState({ current_period: value });
    };

    render(){
        const { loading, tabs, current_tab, current_period, periods } = this.state;
        return (
            <div className="dashboard_wrapper">
                <div className="tabs_wrapper">
                    <Tabs
                        value={current_tab}
                        onChange={this.changeTab}
                        classes={{
                            flexContainer: 'tabs_buttons_container'
                        }}
                    >
                        {tabs.map(({ label, value }) => (
                            <Tab
                                key={value}
                                label={label}
                                value={value}
                                classes={{
                                    root: 'tab_wrapper',
                                    selected: 'tab_selected'
                                }}
                            />
                        ))}
                    </Tabs>
                </div>
                <div className="periods_wrapper">
                    {periods.map(({ label, value }) => (
                        <div className={clsx("period", value === current_period && "active")} key={label} onClick={() => this.changePeriod(value)}>
                            {label}
                        </div>
                    ))}
                </div>
                {loading ? <Loader/> :
                    <div className="chart_wrapper">
                        <LineChart
                            chartData={this.chooseChartData()}
                            current_period={current_period}
                        />
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ dashboard }) => ({
    total_registered_users: dashboard.total_registered_users,
    active_users: dashboard.active_users,
    one_euro_games: dashboard.one_euro_games,
    five_euro_games: dashboard.five_euro_games,
    boy_games: dashboard.boy_games,
    boy_payouts: dashboard.boy_payouts,
    total_deposits: dashboard.total_deposits,
    total_withdrawals: dashboard.total_withdrawals,
    total_bonuses: dashboard.total_bonuses,
});

const mapDispatchToProps = {
    getTotalRegisteredUsers,
    getActiveUsers,
    getBOYGames,
    getBOYPayouts,
    getFiveEuroGames,
    getOneEuroGames,
    getTotalBonuses,
    getTotalDeposits,
    getTotalWithdrawals
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);