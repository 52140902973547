import React from 'react';

import Logo from '../../../assets/image/loader_logo.svg';

import './Loader.scss';

const Loader = (props) => {
    return (
        <div className="loader_wrapper">
            <div className="loading">
                Loading
            </div>
            <div className="loader_container">
                <img src={Logo} alt="Gaming Stars" />
            </div>
        </div>
    );
};

export default Loader;