import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import moment from "moment";

const Line = ({ chartData: { data, labels, categoriesLabels, tooltipLine }, current_period }) => {
    const status= data[1].length ? 'superAdmin' : 'admin';
    const options = {
        title: {
            text: null
        },
        tooltip: {
            borderWidth: 0,
            borderRadius: 4,
            backgroundColor: "#080B1B",
            formatter: function() {
                return `<span style="color:rgba(255, 255, 255, 0.5); opacity: 0.5; font-size: 12px;">${moment(
                    this.x
                ).format("D MMM YYYY")}</span><br/><span style="color:#FFFFFF; font-size: 32px; font-weight: 400;">${
                    this.y.toFixed(0)
                    } <span style="color:#FFFFFF; font-size: 16px; font-weight: 300; text-transform: uppercase;">${tooltipLine}</span></span>`;
            },
            style: {
                fontFamily: "Inter,sans-serif"
            }
        },
        credits: {
            enabled: false
        },
        chart: {
            type: "line",
            height: 500,
            backgroundColor: "transparent",
            marginTop: 30,
            spacingRight: 40,
        },
        xAxis: {
            type: "datetime",
            gridLineWidth: 1,
            gridLineColor: "rgba(56, 63, 70, 0.75)",
            tickColor: "#969a9e",
            lineColor: "#969a9e",
            lineWidth: 2,
            tickWidth: 2,
            tickLength: 6,
            title: {
                text: labels['x'],
                style: {
                    fontFamily: 'Inter,sans-serif',
                    color: '#FFFFFF'
                },
                align: 'high'

            },
            labels: {
                style: {
                    color: "rgba(255, 255, 255, 0.5)"
                },
                formatter: function() {
                    return moment(this.value).format("D MMM YY");
                }
            }
        },
        yAxis: {
            gridLineWidth: 1,
            gridLineColor: "rgba(56, 63, 70, 0.75)",
            tickColor: "#969a9e",
            lineColor: "#969a9e",
            lineWidth: 2,
            tickWidth: 2,
            tickLength: 6,
            title: {
                text: labels['y'],
                style: {
                    fontFamily: 'Inter,sans-serif',
                    color: '#FFFFFF'
                },
                align: 'high',
                rotation: 0,
                y: -20,
                offset: -15
            },
            labels: {
                style: {
                    color: "rgba(255, 255, 255, 0.5)"
                }
            }
        },
        plotOptions: {
            line: {
                marker: {
                    enabled: true,
                    enabledThreshold: 1
                },
                lineWidth: 3
            }
        },
        series: status === 'superAdmin'
            ? [
                {
                    showInLegend: true,
                    name: categoriesLabels['real'],
                    data: current_period !== -1 ? data[0].filter(el => moment().subtract(current_period, 'd').valueOf() <= el[0]) : data[0],
                    color: "#1fa1f6",
                    marker: {
                        symbol: 'circle'
                    }
                },
                {
                    showInLegend: true,
                    name: categoriesLabels['fake'],
                    data: current_period !== -1 ? data[1].filter(el => moment().subtract(current_period, 'd').valueOf() <= el[0]) : data[1],
                    color: "#00adac",
                    marker: {
                        symbol: 'circle'
                    }
                }
            ]
            : [
                {
                    showInLegend: true,
                    name: categoriesLabels['custom'],
                    data: current_period !== -1 ? data[0].filter(el => moment().subtract(current_period, 'd').valueOf() <= el[0]) : data[0],
                    color: "#1fa1f6",
                    marker: {
                        symbol: 'circle'
                    }
                }
            ],
        legend: {
            itemStyle: {
                color: "#FFFFFF",
                fontWeight: "normal",
                fontFamily: "SFProDisplayLight,sans-serif"
            },
            itemHoverStyle: {
                color: "rgba(255, 255, 255, 0.7)"
            },
            margin: 40,
            symbolHeight: 10
        }
    };
    return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default Line;
