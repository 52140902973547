import * as types from "../actions/constants";

const INITIAL_STATE = {
    error_auth: ''
};

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {

        case types.LOGIN_FAIL :
            let authError = '';
            if (action.error.response.data.non_field_errors && action.error.response.data.non_field_errors[0]) {
                authError = action.error.response.data.non_field_errors[0];
            }
            if (action.error.response.data.password && action.error.response.data.password[0]) {
                authError = action.error.response.data.password[0];
            }
            if ( action.error.response.data.email && action.error.response.data.email[0]) {
                authError = action.error.response.data.email[0];
            }
            return {...state, error_auth : authError};

        default:
            return state;
    }
}