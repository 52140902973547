import React, { Component, Fragment } from "react";
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import Dashboard from '../../components/Dashboard/Dashboard';
import Header from '../../components/Header/Header';
import Preloader from '../../components/HelperComponents/Preloader/Preloader';
import { source } from '../../index';

import { getMyProfile } from "../../actions/dashboardActions";

class Container extends Component {
    state = {
        loading: true,
    };

    componentDidMount() {
        const { getMyProfile } = this.props;
        getMyProfile().then(() => {
            this.setState({ loading: false });
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps) {
            if(prevProps.location.pathname !== this.props.location.pathname) {
                source.cancel('Operation canceled by the user.');
            }
        }
    }

    render() {
        const { match, history, profile: { email }} = this.props;
        const { loading } = this.state;
        if(!localStorage.token) return <Redirect to="/auth" />;
        if (loading) return <Preloader />;
        return (
            <Fragment>
                <Header history={history} email={email} />
                <div className="page_wrapper">
                    <Switch>
                        {/*<Route*/}
                        {/*    path={match.url}*/}
                        {/*    exact*/}
                        {/*    render={() => <Redirect to="/main/dashboard"/>}*/}
                        {/*/>*/}
                        {/*<Route path={`${match.url}/dashboard`} component={UserManagement} />*/}
                        <Route path={match.url} exact component={Dashboard} />
                        <Route render={()=>(<p>Not found</p>)} />
                    </Switch>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = ({ dashboard }) => {
    return {
        profile: dashboard.profile
    }
};
const mapDispatchToProps = {
    getMyProfile
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
