import React, {Fragment} from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Login from "../../components/Auth/Login/Login";

import LogoIcon from '../../assets/image/Logo.svg'

import './AuthContainer.scss';

const AuthContainer = (props) => {
    const { match } = props;
    if(!!localStorage.token) return <Redirect to="/admin-dashboard" />;
    return (
        <Fragment>
            <main className="auth_container">
                <img src={LogoIcon} alt="Gaming Start"/>
                <Switch>
                    <Route path={match.url} exact component={Login} />
                    <Route render={()=>(<p>Not found</p>)} />
                </Switch>
            </main>
        </Fragment>
    );
};

export default AuthContainer;