import * as types from './constants.jsx';

export function getMyProfile(data) {
    return {
        type: types.MY_PROFILE,
        payload: {
            client: 'default',
            request: {
                url: `/api/v0/me/`,
                method: "get"
            }
        }
    };
}

export function getTotalRegisteredUsers() {
    return {
        type: types.GET_TOTAL_REGISTERED_USERS,
        payload: {
            client: 'default',
            request: {
                url: `/dashboard/charts/total-registered-users/`,
                method: "get"
            }
        }
    };
}

export function getActiveUsers() {
    return {
        type: types.GET_ACTIVE_USERS,
        payload: {
            client: 'default',
            request: {
                url: `/dashboard/charts/online-at-least-one-week/`,
                method: "get"
            }
        }
    };
}

export function getOneEuroGames() {
    return {
        type: types.GET_ONE_EURO_GAMES,
        payload: {
            client: 'default',
            request: {
                url: `/dashboard/charts/number-1-euro-games/`,
                method: "get"
            }
        }
    };
}

export function getFiveEuroGames() {
    return {
        type: types.GET_FIVE_EURO_GAMES,
        payload: {
            client: 'default',
            request: {
                url: `/dashboard/charts/number-5-25-games/`,
                method: "get"
            }
        }
    };
}

export function getBOYGames() {
    return {
        type: types.GET_BOY_GAMES,
        payload: {
            client: 'default',
            request: {
                url: `/dashboard/charts/number-bet-on-yourself-games/`,
                method: "get"
            }
        }
    };
}

export function getBOYPayouts() {
    return {
        type: types.GET_BOY_PAYOUTS,
        payload: {
            client: 'default',
            request: {
                url: `/dashboard/charts/total-bets/`,
                method: "get"
            }
        }
    };
}

export function getTotalDeposits() {
    return {
        type: types.GET_TOTAL_DEPOSITS,
        payload: {
            client: 'default',
            request: {
                url: `/dashboard/charts/total-deposits/`,
                method: "get"
            }
        }
    };
}

export function getTotalWithdrawals() {
    return {
        type: types.GET_TOTAL_WITHDRAWALS,
        payload: {
            client: 'default',
            request: {
                url: `/dashboard/charts/total-withdrawals/`,
                method: "get"
            }
        }
    };
}

export function getTotalBonuses() {
    return {
        type: types.GET_TOTAL_BONUSES,
        payload: {
            client: 'default',
            request: {
                url: `/dashboard/charts/total-paid-out-bonus-to-users/`,
                method: "get"
            }
        }
    };
}