import * as types from "../actions/constants";

import { parseChartData } from '../helpers/functions';

const INITIAL_STATE = {
    profile: {},

    total_registered_users: {},
    active_users: {},
    one_euro_games: {},
    five_euro_games: {},
    boy_games: {},
    boy_payouts: {},
    total_deposits: {},
    total_withdrawals: {},
    total_bonuses: {},
};

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case types.MY_PROFILE_SUCCESS :
            return {
                ...state,
                profile : action.payload.data
            };
        case types.MY_PROFILE_FAIL :
            localStorage.clear();
            window.location.href = "/auth/";
            return INITIAL_STATE;

        case types.GET_TOTAL_REGISTERED_USERS_SUCCESS :
            return {
                ...state,
                total_registered_users: {
                    data: parseChartData(action.payload.data),
                    labels: {y: "Users", x: "Date"},
                    categoriesLabels: { custom: "Total registered users",  real: "Real registered users", fake: "Fake registered users " },
                    tooltipLine: "Users"
                }
            };
        case types.GET_ACTIVE_USERS_SUCCESS :
            return {
                ...state,
                active_users: {
                    data: parseChartData(action.payload.data),
                    labels: {y: "Users, %", x: "Date"},
                    categoriesLabels: { custom: "Active users", real: "Active real users", fake: "Active fake users " },
                    tooltipLine: "Users"
                }
            };
        case types.GET_ONE_EURO_GAMES_SUCCESS :
            return {
                ...state,
                one_euro_games: {
                    data: parseChartData(action.payload.data),
                    labels: {y: "Games", x: "Date"},
                    categoriesLabels: { custom: "Number of games", real: "Number of real games", fake: "Number of fake games" },
                    tooltipLine: "Games"
                }
            };
        case types.GET_FIVE_EURO_GAMES_SUCCESS :
            return {
                ...state,
                five_euro_games: {
                    data: parseChartData(action.payload.data),
                    labels: {y: "Games", x: "Date"},
                    categoriesLabels: { custom: "Number of games", real: "Number of real games", fake: "Number of fake games" },
                    tooltipLine: "Games"
                }
            };
        case types.GET_BOY_GAMES_SUCCESS :
            return {
                ...state,
                boy_games: {
                    data: parseChartData(action.payload.data),
                    labels: {y: "Games", x: "Date"},
                    categoriesLabels: { custom: "Number of games", real: "Number of real games", fake: "Number of fake games" },
                    tooltipLine: "Games"
                }
            };
        case types.GET_BOY_PAYOUTS_SUCCESS :
            return {
                ...state,
                boy_payouts: {
                    data: parseChartData(action.payload.data),
                    labels: {y: "Payouts, €", x: "Date"},
                    categoriesLabels: { custom: "Total payouts of games", real: "Total payouts of real games", fake: "Total payouts of fake games" },
                    tooltipLine: "Payouts"
                }
            };
        case types.GET_TOTAL_DEPOSITS_SUCCESS :
            return {
                ...state,
                total_deposits: {
                    data: parseChartData(action.payload.data),
                    labels: {y: "Deposits, €", x: "Date"},
                    categoriesLabels: { custom: "Total deposits", real: "Total deposits", fake: "Total deposits" },
                    tooltipLine: "Deposits"
                }
            };
        case types.GET_TOTAL_WITHDRAWALS_SUCCESS :
            return {
                ...state,
                total_withdrawals: {
                    data: parseChartData(action.payload.data),
                    labels: {y: "Withdrawals, €", x: "Date"},
                    categoriesLabels: { custom: "Total withdrawals", real: "Total withdrawals", fake: "Total withdrawals" },
                    tooltipLine: "Withdrawals"
                }
            };
        case types.GET_TOTAL_BONUSES_SUCCESS :
            return {
                ...state,
                total_bonuses: {
                    data: parseChartData(action.payload.data),
                    labels: {y: "Bonuses, €", x: "Date"},
                    categoriesLabels: { custom: "Total bonuses of users", real: "Bonuses of real users", fake: "Bonuses of fake users " },
                    tooltipLine: "Bonuses"
                }
            };
        default:
            return state;
    }
}