import React, {} from "react";
import moment from "moment/moment";

export function getOption(label) {
    return (
        <div className={`status ${label}`}>
            <div>{label !== "All networks" && <span />}{label}</div>
        </div>
    )
}

export function splitByCommas(data) {
    return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function parseChartData(data) {
    let tempData = [ [], [] ];
    data[0].forEach(({ date, value }) => {
        tempData[0].push([moment(date).valueOf(), value]);
    });
    data[2] && data[1].forEach(({ date, value }) => {    //temporary we don't have fake data
        tempData[1].push([moment(date).valueOf(), value]);
    });
    return tempData;
}